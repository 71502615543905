<template>
  <div class="comment__form" @paste="pastFileHandler">
    <div class="comment__textarea">
      <div class="comment__form__close" @click="$emit('closeCommentForm')">
        <vs-icon icon="close"></vs-icon>
      </div>
      <div class="comment__field">
        <quill-editor
          ref="myQuillEditor"
          v-model="comment"
          :options="editorOption"
          v-focus
          @change="storeInCache"
        />

        <div class="comment__emoji" v-click-outside="togglePicker">
          <vs-icon icon="tag_faces" @click="showPicker = !showPicker"></vs-icon>
          <VEmojiPicker v-show="showPicker" @select="selectEmoji" />
        </div>
      </div>
      <div class="comment__upload">
        <upload v-model="files" :clipboardFiles="clipboarFiles" />
      </div>
      <div class="comment__actions">
        <vs-button
          title="Save"
          icon="save"
          color="primary"
          @click="saveComment"
        >
          Save (Ctrl+Enter)
        </vs-button>
        <!-- <vs-button
          type="border"
          icon="attach_file"
          title="Upload File"
          color="dark"
          @click="popupActive = true"
        ></vs-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import setComments from '@/api/comments/setComments'
import upload from '@/components/files/commonUploadForm.vue'
import { VEmojiPicker } from 'v-emoji-picker'
import vClickOutside from 'v-click-outside'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import eventBus from '@/eventBus.js'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'CommentForm',
  props: ['itemType', 'itemId', 'commentItem'],
  directives: {
    clickOutside: vClickOutside.directive,
    focus: {
      // определение директивы
      inserted: function(el) {
        setTimeout(() => {
          el.querySelector('.ql-editor').focus()
        }, 50)
      },
    },
  },
  computed: {
    editor: function() {
      return this.$refs.myQuillEditor.quill
    },
  },
  data() {
    var bindings = {
      custom: {
        key: 'Enter',
        ctrlKey: true,
        handler: function() {
          this.saveComment()
        }.bind(this),
      },
    }
    let key = `${this.itemType}-${this.itemId}`
    if (this.commentItem) {
      const object_type = this.commentItem.link.type
      const object_id = this.commentItem.link.id
      const { id } = this.commentItem
      key = `${object_type}-${object_id}-${id}`
    }
    return {
      comment: this.commentItem
        ? localStorage.getItem(`${key}`) || this.commentItem.comment
        : localStorage.getItem(`${key}`),
      id: this.commentItem ? this.commentItem.id : null,
      files: this.commentItem ? this.commentItem.files : [],
      showPicker: false,
      popupActive: false,
      clipboarFiles: [],
      storeKey: key,
      editorOption: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            ['clean'],
          ],
          keyboard: {
            bindings,
          },
        },
      },
    }
  },
  components: {
    VEmojiPicker,
    quillEditor,
    upload,
  },
  methods: {
    saveComment: async function() {
      const { files, comment } = this

      const data = {
        comment,
        files: files.map(f => f.id),
        object_type: this.itemType,
        object_id: this.itemId,
        teacher_id: this.id ? this.commentItem.teacher.id : this.$getUser().id,
      }
      if (this.id) data.id = this.id
      setComments
        .call(this, { data: [data] })
        .then(response => {
          if (response.status == 'OK') {
            // this.$emit('addToList', response.data)
            eventBus.$emit('updateCommentsList')

            this.$emit('updateCommentsList')
            this.$emit('closeCommentForm', response.data)
            localStorage.removeItem(this.storeKey)
          } else {
            console.log(response.message)
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    selectEmoji: function(emoji) {
      var range = this.editor.getSelection(true)
      this.editor.insertText(range ? range.index : 0, emoji.data)
    },
    togglePicker: function() {
      this.showPicker = false
    },
    pastFileHandler: function(e) {
      if (e.clipboardData.files.length) {
        e.preventDefault()
        this.clipboarFiles = e.clipboardData.files
      }
    },
    storeInCache: function() {
      localStorage.setItem(this.storeKey, this.comment)
    },
  },
}
</script>

<style lang="scss">
.comment__form {
  .comment__form__close {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 10;
    i {
      border-radius: 50%;
      padding: 7px;
      transition: 0.3s all;
      &:hover {
        background-color: #ddd;
      }
    }
  }

  textarea {
    height: 160px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .comment__form__add-button {
    margin-bottom: 10px;
  }
  .comment__textarea {
    position: relative;
    background-color: #f7f7f7;
    margin-bottom: 10px;
    padding: 35px 20px 20px 20px;
  }
  &.small {
    .comment__textarea {
      margin-bottom: 0px;
      padding: 25px 5px 5px 5px;
    }
  }
  button {
    float: none !important;
  }
  .comment__field {
    position: relative;

    .comment__emoji {
      position: absolute;
      top: 1em;
      right: 7px;
      .vs-icon {
        opacity: 0.8;
      }
      cursor: pointer;
      &.active {
        .vs-icon {
          opacity: 1;
        }
      }
      #EmojiPicker {
        position: absolute;
        right: 100%;
        z-index: 101;
      }
    }
  }
  .comment__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin-right: 15px;
    }
  }
}
</style>

import Vue from 'vue'

export default function exportActiveStudents(query) {
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url = proxy + api_uri + '/groups-export'
  if (query) {
    let q = new URLSearchParams(query).toString()
    url += `?${q}`
  }
  return Vue.http
    .get(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      }
    )
    .then(response => response)
    .catch(r => {
      throw r
    })
}

<template>
  <div class="teacher-note-edit-form">
    <div class="note__textarea">
      <div class="note__form__close" @click="$emit('closeForm')">
        <vs-icon icon="close"></vs-icon>
      </div>
      <div class="note__field">
        <quill-editor
          ref="myQuillEditor"
          v-model="noteBody"
          :options="editorOption"
          v-focus
          @change="storeInCache"
        />

        <div class="note__emoji" v-click-outside="togglePicker">
          <vs-icon icon="tag_faces" @click="showPicker = !showPicker"></vs-icon>
          <VEmojiPicker v-show="showPicker" @select="selectEmoji" />
        </div>
      </div>
      <div class="note__actions">
        <vs-button title="Save" icon="save" color="primary" @click="saveNote">
          Save (Ctrl+Enter)
        </vs-button>
        <!-- <vs-button
          type="border"
          icon="attach_file"
          title="Upload File"
          color="dark"
          @click="popupActive = true"
        ></vs-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import vClickOutside from 'v-click-outside'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  props: ['note'],
  directives: {
    clickOutside: vClickOutside.directive,
    focus: {
      // определение директивы
      inserted: function(el) {
        setTimeout(() => {
          el.querySelector('.ql-editor').focus()
        }, 50)
      },
    },
  },
  computed: {
    editor: function() {
      return this.$refs.myQuillEditor.quill
    },
  },
  data() {
    var bindings = {
      custom: {
        key: 'Enter',
        ctrlKey: true,
        handler: function() {
          this.saveNote()
        }.bind(this),
      },
    }
    let key = `Note-${this.note.lesson.id}`
    if (this.note.note) {
      key += `-${this.note.id}`
    }
    return {
      noteBody: this.note
        ? localStorage.getItem(key) || this.note.note
        : localStorage.getItem(key),
      showPicker: false,
      popupActive: false,
      storeKey: key,
      editorOption: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            ['clean'],
          ],
          keyboard: {
            bindings,
          },
        },
      },
    }
  },
  components: {
    VEmojiPicker,
    quillEditor,
  },
  methods: {
    saveNote: async function() {
      const { noteBody } = this
      const { lesson, id } = this.note
      const data = {
        note: noteBody,
        lesson_id: lesson.id,
      }
      if (id) {
        data.id = id
      }
      await this.$store
        .dispatch('teachers/saveNoteAction', data)
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: `Note saved`,
            color: 'success',
            position: 'top-right',
          })
          this.$emit('noteSaved')
          this.$emit('closeForm')
          localStorage.removeItem(this.storeKey)
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: `Note not saved`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },

    selectEmoji: function(emoji) {
      var range = this.editor.getSelection(true)
      this.editor.insertText(range ? range.index : 0, emoji.data)
    },
    togglePicker: function() {
      this.showPicker = false
    },
    storeInCache: function() {
      localStorage.setItem(this.storeKey, this.noteBody)
    },
  },
}
</script>

<style lang="scss">
.teacher-note-edit-form {
  .note__form__close {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 10;
    i {
      border-radius: 50%;
      padding: 7px;
      transition: 0.3s all;
      &:hover {
        background-color: #ddd;
      }
    }
  }
  textarea {
    height: 160px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .note__form__add-button {
    margin-bottom: 10px;
  }
  .note__textarea {
    position: relative;
    background-color: #f7f7f7;
    margin-bottom: 10px;
    padding: 35px 20px 20px 20px;
  }
  &.small {
    .note__textarea {
      margin-bottom: 0px;
      padding: 25px 5px 5px 5px;
    }
  }
  button {
    float: none !important;
  }
  .note__field {
    position: relative;

    .note__emoji {
      position: absolute;
      top: 1em;
      right: 7px;
      .vs-icon {
        opacity: 0.8;
      }
      cursor: pointer;
      &.active {
        .vs-icon {
          opacity: 1;
        }
      }
      #EmojiPicker {
        position: absolute;
        right: 100%;
        z-index: 101;
      }
    }
  }
  .note__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin-right: 15px;
    }
  }
}
</style>

<template>
  <div id="app">
    <div id="content">
      <LeftMenu v-if="user && token" />
      <div class="content">
        <Header />
        <div class="page">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
    <rainbow v-if="userData && userData.id == 10" />
    <sloth v-else-if="userData && userData.id == 11" />
  </div>
</template>

<script>
import Header from './components/common/header'
import LeftMenu from './components/common/leftmenu'
import Rainbow from './components/common/decorations/rianbow.vue'
import Sloth from './components/common/decorations/sloth.vue'

export default {
  name: 'App',
  data() {
    return {
      user: localStorage.getItem('user'),
      userData: JSON.parse(localStorage.getItem('user')),
      token: localStorage.getItem('token'),
    }
  },
  components: {
    Header,
    LeftMenu,
    Rainbow,
    Sloth,
  },
  beforeMount: function() {
    this.$store.dispatch('years/getYearsAction')
  },
}
</script>

<style lang="scss">
@import 'components/tasks/styles/task.scss';
:root {
  --var-cancelled: #ffc8f0;
  --var-started: #c8ffcd;
  --font-color: #2c3e50;
  --font-positive-color: #03a800;
  --font-negative-color: #8c0000;
  --font-discount-15: #008c17;
  --font-discount-25: #286cff;
  --font-discount-50: #9e28ff;
  --font-discount-100: #8b8e96;
  --font-primary-color: #1f74ff;
  --element-border-color: #ccc;
  --thead-text-color: #777;
  --sub-color: #7e8299;
  --small-icon-size: 1.1rem;
  --table-danger-bcg: #ffc8c8;
  --table-cancelled-bcg: #ffc8f0;
  --table-wrong-bcg: #fff3c8;
  --table-empty-bcg: #f8e291;
  --table-disabled-bcg: #e6e6e6;
  --table-warn-bcg: #f2c8ff;
  --testing-bcg-color: #f4e2b7cc;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
u {
  text-decoration: underline;
}
s {
  text-decoration: line-through;
}
// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.add-button::after {
  content: '';
  display: block;
  clear: both;
}
#content {
  display: flex;
  min-height: 100vh;
  flex-flow: row nowrap;
  align-items: stretch;
  background: url(/img/bcg.jpg) no-repeat bottom center;
  .content {
    flex: 1;
    flex-grow: 1;
    box-shadow: -2px 0 6px 0 #ccc;
  }
  .page {
    padding: 10px 25px;
  }
  .vs-icon {
    vertical-align: middle;
  }
}
.save-popup-button {
  position: sticky;
  bottom: 30px;
  clear: both;
  z-index: 100;
  margin-top: 15px;
}
.con-vs-avatar {
  vertical-align: middle;
  & > span.material-icons {
    font-size: 1em;
  }
}
.icon-alert {
  padding: 6px !important;
}
.vs-table--tbody-table tr {
  border-bottom: 1px solid #eee;
  &:nth-child(2n) {
    background-color: #fafafa;
  }
}
.form-content-two-parts {
  display: flex;
  flex-flow: row nowrap;
  .inline-part {
    flex: 1;
    padding: 15px;
  }
}
.con-select {
  margin-top: 15px;
  .vs-select--input {
    padding: 10px 20px 10px 10px;
  }
}
.group-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0 5px 0 10px;
}
.con-vs-popup {
  z-index: 1000;
  &.small-popup {
    .vs-popup {
      width: auto;
    }
  }
  &.popup__lessons {
    position: absolute;
    z-index: 20001;
    .vs-popup {
      width: 40%;
    }
  }
  &.fullscreen {
    .vs-popup {
      height: auto !important;
    }
  }
  .popup-content {
    overflow: visible;
  }
}
.small-popup {
  .vs-popup {
    width: auto !important;
  }
}

.split {
  min-height: Calc(100vh - 115px);
  height: 1px !important;
  // padding: 0 5px;
  & > h2 {
    margin-top: 15px;
  }
}
.status-bar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  h2 {
    margin: 0 5px;
    text-align: left;
  }
  .status-bar__manage-buttons {
    margin-left: 20px;
    button {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    &.full-size-button {
      flex: 1;
    }
    & > div {
      display: inline-block;
    }
    &.current_books {
      display: flex;
      flex-flow: row nowrap;
    }
  }
  &__manage-delimiter {
    flex: 1;
  }
}
.panel-view {
  margin-top: 0.3rem;
}
.visits {
  .visits__months {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin: 5px;
    }
    .month-name {
      min-width: 100px;
      text-align: center;
    }
  }
  table {
    th:nth-child(1) {
      width: 255px;
    }
    tbody {
      tr {
        height: 2.7rem;
      }
    }
  }
  .current-static {
    font-size: 0.9em;
    max-width: 22rem;
    .current-static__item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
}
.vs-con-table header {
  min-height: 50px;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: #eee;
}
.vs-con-table {
  background-color: #fff;
  table {
    font-size: 1;
  }
  .vs-table--content {
    overflow: initial;
  }
}
.vs-component.con-vs-checkbox {
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}
.vs-con-textarea {
  margin-top: 15px;
}

.order__table {
  border-collapse: collapse;
  td,
  th {
    padding: 10px 15px;
    text-align: left;
    font-size: 0.8em;
    max-width: 100px;
  }
  th {
    word-wrap: break-word;
    border-bottom: 1px solid #ccc;
  }
  tr {
    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
}
.orders__header {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
.credit {
  &.negative {
    color: var(--font-negative-color);
  }
}
.discount {
  &.d15 {
    color: var(--font-discount-15);
  }
  &.d25 {
    color: var(--font-discount-25);
  }
  &.d50 {
    color: var(--font-discount-50);
  }
}
.highlight {
  font-weight: 700;
}
.order-info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & > * {
    margin-right: 15px;
  }
  .con-vs-chip {
    float: none !important;
  }
}
.order__table-wrapper {
  table {
    tbody {
      tr {
        cursor: pointer;
        transition: 0.2s all;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
.payment__popup .vs-popup {
  width: 90% !important;
  max-width: 1300px;
}
.field {
  margin: 15px 0 0 0;
  label {
    display: block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.6);
  }
  .error {
    color: red;
    font-size: 0.8rem;
    border-color: red;
  }
}

.crossed {
  text-decoration: line-through;
}
.vs-tabs--btn {
  .vs-icon {
    font-size: 0.9em;
  }
}
.split-horizontal {
  position: relative;
  .expand-right-panel {
    left: -20px;
    top: 5px;
    position: absolute;
    z-index: 10;
  }
}
.source {
  .con-vs-chip {
    background-color: #ddd;
    .text-chip {
      color: var(--font-color);
    }
  }
}
.con-vs-loading {
  position: absolute !important;
  justify-content: flex-start !important;
  .vs-loading {
    margin: auto;
  }
}
.vs__dropdown-option--disabled {
  display: none !important;
}
.collapsible-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}
.collapsible__button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    .collapsible__button__arrow {
      background-color: #f5f5f5;
    }
  }
  cursor: pointer;

  & > * {
    color: var(--font-color);
  }
  .title {
    margin: 0 7px;
  }
  .collapsible__button__arrow {
    padding: 2px;
    margin-right: 7px;
    border-radius: 5px;
  }
}
.collapsible {
  margin-bottom: 5px;
  .collapsible_content {
    border-radius: 10px;
    padding: 15px 12px;
    margin-top: 5px;
  }

  .opened {
    & > div {
      opacity: 1;
    }
    .collapsible__button__arrow {
      transform: rotate(90deg);
      transition: 0.2s all;
      background-color: #f5f5f5;
    }
  }
}
.vs-tabs-primary {
  .con-ul-tabs {
    .ul-tabs {
      padding-left: 10px;
    }
    .vs-tabs--li {
      display: block;
      position: relative;
      padding: 17px 3px;
      &.activeChild button,
      button:not(:disabled):hover {
        color: #fff !important;
        box-shadow: 0 0 10px 4px #eee;
        background-color: var(--font-primary-color) !important;
        i {
          color: #fff !important;
        }
      }
    }
    .vs-tabs--li button.vs-tabs--btn {
      padding: 7px 15px;
      background-color: #eee;
      border-radius: 7px;
    }

    .line-vs-tabs {
      display: none;
    }
  }
}
.v-select {
  .vs__dropdown-toggle {
    background-color: #fff;
  }
}
.group__balance,
.book__price {
  font-size: 0.9em;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #f1f1f1;
  display: flex;
  align-self: center;
}
.lesson-in-work {
  color: var(--font-primary-color);
  font-weight: bold;
  padding: 0 7px;
  font-size: 0.8em;
}
.lesson-in-done {
  color: var(--font-positive-color);
  font-weight: bold;
  padding: 0 7px;
  font-size: 0.8em;
}
.quill-editor {
  background-color: #fff;
  border-radius: 7px;
  margin: 1rem 0;
  .ql-toolbar.ql-snow {
    border-radius: 7px 7px 0 0;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 7px 7px;
    .ql-editor {
      min-height: 10rem;
    }
  }
}
// стили для результатов тестов
.col-test {
  &.low {
    .sub {
      color: rgba(var(--vs-danger));
    }
  }
  &.middle {
    .sub {
      color: rgba(var(--vs-warning));
    }
  }
  &.subhigh {
    .sub {
      color: rgba(var(--vs-dark));
    }
  }
  &.high {
    .sub {
      color: rgba(var(--vs-success));
    }
  }
}
.status-block {
  & > span {
    font-size: 0.7rem;
    text-transform: uppercase;
    padding: 3px;
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
  }
}
.infinite-loading-container {
  padding: 1rem 0.5rem;
  color: var(--sub-color);
  font-size: 0.8rem;
}
.result-data {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin: 25px 0;
  max-width: 400px;
  & > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px 20px;
  }
  &__label {
    font-weight: bold;
    display: block;
    width: 120px;
    &:after {
      content: ':';
    }
  }
  &__data {
    width: 60%;
  }
  &__result {
    font-weight: bold;
    position: relative;
    &:before {
      content: '';
      width: 4px;
      height: 100%;
      background-color: rgba(var(--vs-primary), 1);
      position: absolute;
      top: 0;
      left: 0px;
      border-radius: 4px;
    }
  }
}
.result-comments {
  max-width: 700px;
}
.save-tooltip {
  position: absolute;
  left: 100%;
  top: -1px;
  // right: 0;
  // top: 100%;
  background-color: #eee;
  border-radius: 0 5px 5px 0;
  border: 1px solid #bbb;
  box-shadow: 3px 0 15px 1px #ccc;
  z-index: 200;
  .save-tooltip__content {
    display: flex;
    flex-flow: row nowrap;
    button {
      float: none !important;
      &.vs-button {
        padding: 0;
      }
    }
  }
}
.quick-filter {
  display: inline-block;
  margin: 5px 7px 5px 0;
  font-size: 0.8rem;
  color: var(--font-primary-color);
  border-bottom: 1px dashed var(--font-primary-color);
  cursor: pointer;
}
.comment__field {
  position: relative;

  .comment__emoji {
    position: absolute;
    top: 1em;
    right: 7px;
    .vs-icon {
      opacity: 0.8;
    }
    cursor: pointer;
    &.active {
      .vs-icon {
        opacity: 1;
      }
    }
    #EmojiPicker {
      position: absolute;
      right: 100%;
      z-index: 101;
    }
  }
}
.con-vs-chip.book-type {
  margin-left: 5px;
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--sub-color);
  color: #fff !important;
  cursor: pointer;
  display: flex;
  align-self: center;

  .vs-chip--text {
    margin-left: 4px !important;
  }
}
.con-vs-popup.teacher-tasks-popup-edit {
  z-index: 20001;
}
.markdown {
  font-weight: bold;
  padding: 0.2rem 0.3rem;
  border-radius: 3px;
  margin-left: 5px;
  &.danger {
    color: #c00;
    background-color: #cc000055;
  }
  &.success {
    background-color: rgba(0, 126, 0, 0.5);
    color: #f1f1f1;
  }
  &.warning {
    background-color: #f7b32d;
  }
  &.grey {
    background-color: #f1f1f1;
  }
  &.small {
    font-size: 0.7rem;
  }
}
.con-vs-popup.teachers__popup {
  .vs-popup {
    width: 75%;
  }
}
</style>
